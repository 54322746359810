<template>
  <div class="agile-content__container flex-1 flex flex-col">
    <div class="text-gray-caption text-sm -mx-1 py-2">
      <router-link
        class="hover:underline px-1"
        :to="{ name: 'Board-Completed-Sprint' }"
      >
        {{ $t('agile.compleate.compleate') }}
      </router-link>
      <span>/</span>
      <span class="px-1">{{ sprintName }} </span>
    </div>
    <div class="flex items-start justify-between mb-5 flex-wrap -mx-5">
      <div class="px-5">
        <header class="text-3xl">{{ sprintName }}</header>
        <div class="divide-x -mx-2 text-sm mt-1">
          <span class="px-2 text-gray-caption">
            {{ $t('input.complete') }}
            <span class="font-medium">
              {{ completedBy.name }}
            </span>
          </span>
          <span class="text-red-danger px-2">
            {{ $t('agile.boardComplete.close') }}
            {{ closedAt }}
          </span>
        </div>
        <p class="text-sx text-2-line text-gray-caption mt-2">
          {{ sprint.goal }}
        </p>
      </div>
      <div
        v-if="isFilterVisible"
        class="px-5 flex-shrink-0 flex items-center justify-end flex-1 mt-5 space-x-4"
      >
        <div v-if="isClearVisible" class="ml-2 pr-4 border-r">
          <base-button
            color="brand-outline"
            class="bg-brand-lighter text-sm"
            @click="clearResult"
          >
            {{ $tc('counts.searchResult', tasks.length) }}
            <fa-icon class="ml-2" icon="times-circle" />
          </base-button>
        </div>
        <select-tags v-model="queryParams.tagIDs">
          <base-button color="transparent" wide="px-4 py-2">
            {{ $t('agile.backlog.tag') }}
            <span v-if="countActiveTags">
              :
              <span class="agile-badge badge--brand badge--slim">
                {{ countActiveTags }}
              </span>
            </span>
            <base-icon class="ml-1" icon="chevron-down" size="14" />
          </base-button>
        </select-tags>
        <board-members
          ref="selectMembers"
          @change="queryParams.assigneeIDs = $event"
        />
        <base-input-text
          v-model="queryString"
          class="flex-shrink-0 search--completed-task input-pr-8"
          inner-class="base-input--gray"
          :placeholder="
            `${$t('agile.boardComplete.placeholder')} ${sprintName}`
          "
          icon-fa="search"
          :icon-right="queryString ? 'IconClose' : null"
          @icon-right-click="queryString = ''"
        />
      </div>
    </div>
    <div v-if="isFilterVisible">
      <table-tasks
        v-if="this.tasks.length || queryLoading"
        class="flex-1"
        :loading="queryLoading"
        :value="tasks"
        disabled
        @click="openTask"
      />
      <div v-else>
        <base-empty
          :image="require('@/assets/image/no-sprint-search.svg')"
          :title="$t('agile.emtyPage.search.title')"
        >
          <div class="text-2-line text-base font-normal">
            <span>
              {{ $t('agile.emtyPage.search.description') }}
            </span>
            <strong class="text-black "> "{{ queryString }}" </strong>
          </div>
        </base-empty>
      </div>
      <router-view />
      <modal-task
        :share-disabled="false"
        disabled
        comment-disabled
        modal-name="task-detail-completed"
        @closed="onTaskClosed"
      />
    </div>
    <div v-else>
      <base-empty
        class="capitalize"
        :image="require('@/assets/image/no-sprint-search.svg')"
        :title="$t('agile.emtyPage.noTasks')"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import apisSprints from '@/services/apis-sprints';
import MembersVisible from '@/mixins/MembersVisible';
import TableTasks from '@/components/table/table-tasks.vue';
import SelectTags from '@/components/select/select-tags.vue';
import BoardMembers from '@/components/board/board-members.vue';
import ModalTask from '@/components/modal/modal-task.vue';
import QueryList from '@/mixins/QueryListMixin';
import { boardsApi } from '@/services/apis';

const queryToArray = query =>
  query ? query.split(',').map(e => parseInt(e, 10)) : [];

export default {
  components: {
    TableTasks,
    SelectTags,
    BoardMembers,
    ModalTask
  },
  mixins: [QueryList, MembersVisible],
  data() {
    const { sprint_id: sprintID } = this.$route.params;
    const { q = '', tagIDs, assigneeIDs } = this.$route.query;
    return {
      hasInitialQuery: Boolean(q || tagIDs || assigneeIDs),
      taskCounts: 0,
      sprint: {},
      assignee: null,
      fetchTasksInclusiveParams: {
        sprintID
      },
      queryString: q,
      queryParams: {
        sprintID,
        tagIDs: queryToArray(tagIDs),
        assigneeIDs: queryToArray(assigneeIDs)
      }
    };
  },
  computed: {
    ...mapGetters('members', ['getMembers']),
    ...mapGetters('tags', ['getTags']),
    tasks() {
      return this.queryList;
    },
    sprindId() {
      return this.$route.params.sprint_id;
    },
    sprintName() {
      return this.sprint.name || 'Sprint 0';
    },
    closedAt() {
      return moment(this.sprint.completedAt).format('DD MMM YYYY');
    },
    completedBy() {
      return this.sprint.completedByUser || {};
    },
    tagOptions() {
      const tags = this.getTags.map(t => ({ label: t.name, value: t.id }));
      return tags;
    },
    assignedUsers() {
      return this.getMembers.map(e => e.user);
    },
    countActiveTags() {
      return this.queryParams.tagIDs.length;
    },
    isClearVisible() {
      const hasSomeFilterActive = [
        this.queryString,
        this.queryParams.tagIDs,
        this.queryParams.assigneeIDs
      ].some(f => f.length);
      return hasSomeFilterActive;
    },
    isFilterVisible() {
      return this.hasInitialQuery || this.taskCounts || this.queryLoading;
    },
    routerQuery() {
      return {
        tagIDs: this.queryParams.tagIDs,
        assigneeIDs: this.queryParams.assigneeIDs
      };
    }
  },
  created() {
    this.fetchSprint();
  },
  methods: {
    async fetchSprint() {
      return apisSprints.get(this.sprindId).then(res => {
        this.sprint = res.data;
      });
    },
    async queryFetcher({ params }) {
      const { boardID, q, tagIDs, assigneeIDs } = params;
      const res = await boardsApi.tasksByBoard(boardID, {
        params: { sprintID: this.sprindId, q, tagIDs, assigneeIDs }
      });
      if (this.taskCounts < res.data.length) {
        this.taskCounts = res.data.length;
      }
      return res;
    },
    openTask(task) {
      if (this.$route.params.task_id) return;
      this.$router.push({
        name: 'Board-Completed-Sprint-Task',
        params: {
          task_id: task.id
        }
      });
    },
    setAssignee(user) {
      this.assignee = this.assignee !== user.id ? user.id : null;
    },
    clearResult() {
      this.queryString = '';
      this.queryParams.tagIDs = [];
      this.queryParams.assigneeIDs = [];
      this.$refs.selectMembers.clearSelected();
    },
    onTaskClosed() {
      this.$router.push({
        name: 'Board-Completed-Sprint-Id'
      });
    }
  }
};
</script>

<style></style>

TableTasks
