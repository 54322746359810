<template>
  <tr class="table-tasks__row" body-padding="px-6 py-4">
    <td class="table-tasks__title">
      <p class="text-xs text-gray-caption">
        {{ task.code }}
      </p>
      <header class="text-2-line">{{ task.name }}</header>
      <div class="flex items-center" style="margin-top: 6px">
        <select-priority
          readonly
          class="mr-2"
          :value="task.priority"
          disabled
          :label="false"
        />
        <span class="tag tag--story-point mr-2">
          {{ task.storyPoint || '-' }}
        </span>
        <div
          v-if="task.tags"
          class="flex items-center overflow-hidden space-x-1"
        >
          <span>{{ $t('agile.backlog.tag') }}: </span>
          <select-task-tags
            :key="screen.width"
            :lines="1"
            class="flex-1"
            no-add
            tag-max-width="20%"
            :hidden-at="4"
            :task="task"
          />
        </div>
      </div>
    </td>
    <td class="table-tasks__assignees">
      <base-avatar
        class="inline-flex"
        :list="assignedUsers"
        size-small
        popup
        @click.native.stop
      />
    </td>
    <td class="table-tasks__status">
      <div class="agile-badge whitespace-no-wrap">
        <info-dot-agile-status :status="column.status" class="mr-1" />

        <span class="truncate"> {{ status }}</span>
      </div>
    </td>
    <td class="table-tasks__end-at">
      <div>
        <my-task-task-time
          class="text-center inline-block"
          :end-at="task.endAt"
          :diff-date="task.endAt && dayDiff(task.endAt)"
          :status="task.status"
        />
      </div>
    </td>
    <td class="table-tasks__desc">
      <div
        class="text-2-line text-sm task-description"
        v-html="task.description"
      />
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import DateTime from '@/mixins/DateTimeMixin';
import MyTaskTaskTime from '@/components/my-task/my-task-task-time';
import InfoDotAgileStatus from '../info/info-dot-agile-status.vue';
import SelectPriority from '../select/select-priority.vue';
import SelectTaskTags from '../select/select-task-tags.vue';
import WindowResize from '@/mixins/WindowResize';

export default {
  mixins: [DateTime, WindowResize],
  components: {
    SelectPriority,
    SelectTaskTags,
    InfoDotAgileStatus,
    MyTaskTaskTime
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    column() {
      return this.task.boardColumn;
    },
    status() {
      return this.column?.name;
    },
    assignedUsers() {
      return this.task.assigns?.map(e => e.user);
    },
    endDate() {
      if (!this.task.endAt) return '-';
      return moment(this.task.endAt)
        .locale(`${this.$t('notifications.language')}`)
        .format('ddd D MMM YYYY');
    },
    isDue() {
      if (!this.task.endAt) return false;
      const endAt = this.task.endAt;
      return moment().isSameOrAfter(endAt);
    }
  }
};
</script>

<style scoped>
.table-tasks__row {
  @apply bg-white opacity-100;
}
.table-tasks__row td:first-of-type {
  @apply border-r;
}
</style>
