<template>
  <table-loading :loading="loading" responsive>
    <table class="sprint-table table-tasks">
      <thead>
        <tr>
          <th class="table-tasks__title">
            {{ $t('task.title_task') }}
          </th>
          <th class="table-tasks__assignees">
            {{ $t('task.assignee') }}
          </th>
          <th class="table-tasks__status">
            {{ $t('modelTask.status.name') }}
          </th>
          <th class="table-tasks__end-at">
            {{ $t('input.end_date') }}
          </th>
          <th class="table-tasks__desc">
            {{ $t('modelTask.task.description') }}
          </th>
        </tr>
      </thead>

      <v-draggable
        v-show="!loading"
        ref="tableBody"
        v-model="tasks"
        v-bind="draggableConfig"
        :disabled="disabled"
        group="sprint-tasks"
        direction="vertical"
        animation="100"
        tag="tbody"
        filter=".undraggable"
        drag-class="table-tasks__row--drag"
        :empty-insert-threshold="80"
        :style="{ height }"
        @start="dragstart"
        @end="dragend"
        @change="$emit('change', $event)"
      >
        <table-tasks-row
          v-for="task in tasks"
          ref="tableRows"
          :key="task.id"
          :task="task"
          :style="{ height: rowHeights[task.id] }"
          @click.native="$emit('click', task)"
        />
      </v-draggable>
    </table>
  </table-loading>
</template>

<script>
import VDraggable from 'vuedraggable';
import VDraggableMixin from '@/mixins/Vuedraggable';
import TableTasksRow from './table-tasks-row';
import TableLoading from './table-loading.vue';
export default {
  mixins: [VDraggableMixin],
  components: {
    VDraggable,
    TableTasksRow,
    TableLoading
  },
  props: {
    loading: Boolean,
    disabled: Boolean,
    value: {
      type: Array
    },
    freeze: Boolean
  },
  data() {
    return {
      dragging: false,
      height: 'unset',
      rowHeights: {}
    };
  },
  computed: {
    tasks: {
      get() {
        return this.value;
      },
      set(tasks) {
        this.$emit('input', tasks);
      }
    },
    hasFooter() {
      console.log(this.$slots);
      return !!this.$slots.footer;
    }
  },
  watch: {
    freeze(freeze) {
      this.height = freeze
        ? this.$refs.tableBody.$el.offsetHeight + 'px'
        : 'unset';
      this.$refs.tableRows.forEach((row, i) => {
        this.rowHeights[this.tasks[i].id] = freeze
          ? row.$el.offsetHeight + 'px'
          : 'unset';
      });
    }
  },
  methods: {
    dragstart() {
      this.dragging = true;
      this.$emit('dragstart');
    },
    dragend() {
      this.dragging = false;
      this.$emit('dragend');
    }
  }
};
</script>

<style></style>
